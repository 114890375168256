@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .max-line-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .max-line-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@layer base {
  :root {
    --color-neutral-10: 255 255 255;
    --color-neutral-20: 245 245 245;
    --color-neutral-30: 237 237 237;
    --color-neutral-40: 224 224 224;
    --color-neutral-50: 194 194 194;
    --color-neutral-60: 158 158 158;
    --color-neutral-70: 117 117 117;
    --color-neutral-80: 97 97 97;
    --color-neutral-90: 64 64 64;
    --color-neutral-100: 10 10 10;

    --color-primary: 242 193 75;
    --color-primary-surface: 255 252 245;
    --color-primary-border: 248 224 165;
    --color-primary-hover: 202 161 63;
    --color-primary-pressed: 81 64 25;
    --color-primary-focus: 242, 193, 75, 0.2;

    --color-danger: 203 58 49;
    --color-danger-surface: 255 244 242;
    --color-danger-border: 238 180 176;
    --color-danger-hover: 189 37 28;
    --color-danger-pressed: 115 25 18;
    --color-danger-focus: 203, 58, 49, 0.2;

    --color-warning: 205 123 46;
    --color-warning-surface: 255 249 242;
    --color-warning-border: 238 206 176;
    --color-warning-hover: 191 105 25;
    --color-warning-pressed: 115 64 17;
    --color-warning-focus: 205, 123, 46, 0.2;

    --color-success: 67 147 108;
    --color-success-surface: 247 247 247;
    --color-success-border: 184 219 202;
    --color-success-hover: 54 122 89;
    --color-success-pressed: 32 87 61;
    --color-success-focus: 67, 147, 108, 0.2;

    --color-info: 62 143 174;
    --color-info-surface: 245 252 255;
    --color-info-border: 191 218 228;
    --color-info-hover: 52 119 145;
    --color-info-pressed: 31 71 87;
    --color-info-focus: 62, 143, 174, 0.2;
  }
}

body {
  font-family: 'Quicksand', sans-serif;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

.spinner {
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes scan-line {
  0% {
    top: 0;
  }
  50% {
    top: 100%;
  }
  100% {
    top: 0;
  }
}

.animate-scanning-line {
  animation: scan-line 2s infinite;
}

.animate-scanning-line {
  box-shadow: 0 0 50px rgba(0, 255, 0, 0.7);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
